@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("../font/stylesheet.css");
@import url("fontello.css");
@import url("magiczoom.css");
@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-300.eot");
  src: url("../pr/Proxima-Nova-300.eot") format("eot"),
    url("../pr/Proxima-Nova-300.woff2") format("woff2"),
    url("../pr/Proxima-Nova-300.woff") format("woff"),
    url("../pr/Proxima-Nova-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-Regular.eot");
  src: url("../pr/Proxima-Nova-Regular.eot") format("eot"),
    url("../pr/Proxima-Nova-Regular.woff2") format("woff2"),
    url("../pr/Proxima-Nova-Regular.woff") format("woff"),
    url("../pr/Proxima-Nova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-Semibold.eot");
  src: url("../pr/Proxima-Nova-Semibold.eot") format("eot"),
    url("../pr/Proxima-Nova-Semibold.woff2") format("woff2"),
    url("../pr/Proxima-Nova-Semibold.woff") format("woff"),
    url("../pr/Proxima-Nova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-Bold.eot");
  src: url("../pr/Proxima-Nova-Bold.eot") format("eot"),
    url("../pr/Proxima-Nova-Bold.woff2") format("woff2"),
    url("../pr/Proxima-Nova-Bold.woff") format("woff"),
    url("../pr/Proxima-Nova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.input {
  @apply relative bg-opacity-30 h-14 w-full font-light text-sm border-b border-dgrey;
}

.input input,
select {
  @apply bg-transparent border-b border-dinputBorder absolute bottom-0 left-0 w-full block text-dblack text-base;
}

.input label {
  @apply absolute bottom-6 left-0 text-dlabelColor;
}

.input input:focus,
.input select:focus {
  outline: none;
  @apply outline-none border-dblue;
}

.errorInput {
  @apply relative bg-opacity-30 h-14 w-full font-light text-sm;
}

.errorInput input,
select {
  @apply bg-transparent border-b border-dbase absolute bottom-0 left-0 w-full block text-dblack text-base;
}

.errorInput label {
  @apply absolute bottom-6 left-0 text-dbase;
}

.errorInput input:focus,
.errorInput select:focus {
  outline: none;
  @apply outline-none border-dblue;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: unset;
  margin-right: unset;
  text-align: unset;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: unset;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: unset;
}

/* Slider */
.slick-loading .slick-list {
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
}
/* Arrows */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
}

.slick-prev {
  left: -50px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

@supports (scrollbar-color: auto) {
  .scroll-box {
    scrollbar-color: gray gray;
  }
}

/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
@supports selector(::-webkit-scrollbar) {
  .scroll-box::-webkit-scrollbar {
    background: gray;
  }
  .scroll-box::-webkit-scrollbar-thumb {
    background: gray;
  }
}



.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  position: relative;
  bottom: 10px !important;
}

.slick-slide {
  outline: none;
}

.slick-dots {
  bottom: -5px;
}

.slick-dots li button:before {
  font-family: unset;
  font-size: 40px;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "_";
  text-align: center;
  opacity: 1;
  color: #ccc;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  @apply text-dbase;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-track {
  margin-left: 0;
}

#desc {
  padding: 0 10px;
  color: #404553;
  font-size: 15px;
}

#desc ul,
#desc ol {
  padding: 0 10px;
}

#desc li {
  list-style: disc !important;
  margin-left: 20px;
  font-weight: 300 !important;
}

#desc h2,
#desc h3,
#desc h4,
#desc strong {
  font-size: 18px !important;
  display: block !important;
  margin-top: 15px !important;
  margin-bottom: 5px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.pagination {
  @apply flex justify-center w-full my-4;
}

.pagination li {
  @apply mr-0 md:mr-2;
}

.pagination li a {
  @apply block w-12 h-9 text-center leading-9 text-dlabelColor cursor-pointer border border-transparent;
}

.pagination li a:hover,
.active-pagination a {
  @apply bg-white border border-dblack !important;
}

.category-pagination {
  @apply flex justify-center w-full my-4;
}

.category-pagination li {
  margin: 0px 5px;
}

.category-pagination li a {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 200;
  color: rgb(126, 133, 155);
  text-align: center;
  font-size: 1.3333rem;
  text-decoration: none;
}

.category-pagination li a:hover,
.active-pagination-category a {
  border-radius: 3px;
  color: rgb(56 102 223) !important;
  background-color: white;
  box-shadow: rgb(56 102 223) 0px 0px 1px 1px;
}

.product-pagination {
  @apply flex justify-center w-full my-4 p-4;
}

.product-pagination li {
  margin: 0px 5px;
}

.product-pagination li a {
  padding: 13px;
  border-radius: 5px;
  border: 1px solid rgb(226, 229, 241);
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 200;
  color: rgb(126, 133, 155);
  text-align: center;
  font-size: 1.3333rem;
  text-decoration: none;
  font-weight: 600;
}

.product-pagination li a:hover,
.active-pagination-product a {
  border-radius: 3px;
  /* color: rgb(56 102 223) !important; */
  background-color: white;
  /* box-shadow: rgb(56 102 223) 0px 0px 1px 1px; */
}
.arrowLink {
  transition: box-shadow 0.25s ease-in-out 0s;
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgb(234, 239, 243);
  padding: 10px !important;
  color: black !important;
  display: flex;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 18px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.input {
  @apply relative bg-opacity-30 h-14 w-full font-light text-sm;
}

.input input,
select {
  @apply bg-transparent border-b border-dinputBorder absolute bottom-0 left-0 w-full block text-dblack text-base;
}

.input label {
  @apply absolute bottom-6 left-0 text-dlabelColor;
}

.input input:focus,
.input select:focus {
  outline: none;
  @apply outline-none border-dblue;
}

.cart {
  @apply my-4 shadow-md;
}

.cart-header {
  @apply bg-dgrey1 py-3 px-4 bg-opacity-10 flex items-center;
}

.cart-body {
  @apply p-4 bg-white;
}

.cart-icon {
  @apply bg-white w-8 h-8 rounded-full flex items-center justify-center text-xl mr-3;
}

.menu-button {
  @apply flex flex-col items-center justify-center;
}

.menu-button span.menu-icon {
  @apply bg-dbase text-white w-9 h-9 flex justify-center items-center rounded-sm text-lg;
}

.menu-button span {
  @apply text-xs mt-1 block;
}

.required:before {
  content: "*";
  @apply text-dbase;
  font-size: 18px;
  position: absolute;
  top: -2px;
}

.ae {
  background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
  background-position-x: -137px;
  background-position-y: 0px;
  background-size: 186px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  margin: 0 4px;
  border: 1px solid #eee;
  padding: 9px 15px;
  width: 50px;
  height: 30px;
}

.visa {
  background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
  background-position-x: -137px;
  background-position-y: -64px;
  background-size: 186px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-clip: initial;
  background-color: initial;
  margin: 0 4px;
  border: 1px solid #eee;
  padding: 9px 15px;
  width: 50px;
  height: 30px;
}

.cashCard {
  background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
  background-position-x: -137px;
  background-position-y: -96px;
  background-size: 186px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  margin: 0 4px;
  border: 1px solid #eee;
  padding: 9px 15px;
  width: 50px;
  height: 30px;
}

.masterCard {
  background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
  background-position-x: -137px;
  background-position-y: -32px;
  background-size: 186px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  margin: 0 4px;
  border: 1px solid #eee;
  padding: 9px 15px;
  width: 50px;
  height: 30px;
}

.googlePlay {
  background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
  background-position-x: 0px;
  background-position-y: -86px;
  background-size: 186px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  width: 135px;
  padding: 0 20px;
  height: 40px;
}

.appleStore {
  display: inline-block;
  width: 135px;
  height: 40px;
  background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
  background-position-x: 0px;
  background-position-y: 0px;
  background-size: 186px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.proxima-font {
  font-family: PR;
}

.facebook {
  color: #fff;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin: 0 10px; */
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  /* line-height: 24px; */
}

.facebook-desktop {
  color: #fff;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-size: 25px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  /* line-height: 24px; */
}
/* Custom Scrollbar for the specific div */
.custom-scrollbar::-webkit-scrollbar {
  height: 6px; /* Adjust thickness for horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* Customize thumb color */
  border-radius: 4px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Customize track color */
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #cccccc #f0f0f0; /* Thumb color and track color */
}

.facebook-border {
  border: 1px solid rgb(226, 229, 241);
  background-color: white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  align-items: center;
}

.ldds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldds-ellipsis div {
  position: absolute;
  top: 33px;
  text-align: center;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  /* @apply bg-dbase; */
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: ldds-ellipsis1 0.6s infinite;
}
.ldds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: ldds-ellipsis2 0.6s infinite;
}
.ldds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: ldds-ellipsis2 0.6s infinite;
}
.ldds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: ldds-ellipsis3 0.6s infinite;
}
@keyframes ldds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.slick-nav-custom .slick-current {
  border-bottom: 3px solid white;
  transform: scale(1.05);
  position: relative;
  bottom: 1px;
}

.w-0 {
  width: 0px;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-3 {
  width: 0.75rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}
/* @media(max-width: 768px) {
body::-webkit-scrollbar {
    display: none;
  }
} */
/* Hide scrollbar for IE, Edge and Firefox */

@media (max-width: 768px) {
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.atTheTop {
  z-index: 90;
}

::-webkit-scrollbar {
  height: 8px;
  width: 9px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #9d9d9d;
  border-radius: 100px;
}

.mySwiper .swiper-button-next {
  position: absolute !important;
  top: 40% !important;
  transform: translateY(25%);
  height: 60px !important;
  width: 30px !important;
  /* border-radius: 100% !important; */
  border-radius: 4px !important;
  opacity: 0.4;
  font-size: 20px;
  font-weight: 900;
  border: 1px solid rgb(204, 204, 204) !important;
  background: rgb(255, 255, 255) !important;
  z-index: 1;
  margin-right: -10px;
  transition: all 0.5s ease;
}

.mySwiper .swiper-button-prev {
  position: absolute !important;
  top: 40% !important;
  transform: translateY(25%);
  height: 60px !important;
  width: 30px !important;
  /* border-radius: 100% !important; */
  border-radius: 4px !important;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 3px -1px !important;
  border: 1px solid rgb(204, 204, 204) !important;
  background: rgb(255, 255, 255) !important;
  z-index: 1;
  margin-left: -13px !important;
  opacity: 0.4;
  transition: all 0.5s ease;
}

.mySwiper .swiper-button-next:hover {
  opacity: 1;
}

.mySwiper .swiper-button-prev:hover {
  opacity: 1;
}

.mySwiper .swiper-button-prev::after,
.mySwiper .swiper-button-next::after {
  font-size: 14px !important;
  color: rgb(0, 0, 0) !important;
  text-align: center !important;
}

.sliderSwiper .swiper-pagination-bullet {
  width: 22px;
  height: 3px;
  box-shadow: rgb(204 204 204) 0px 0px 1px;
  margin: 0px 4px;
  border-radius: 50px;
  background: rgb(226, 229, 241);
  opacity: 1;
  bottom: 0px;
}

.sliderSwiper .swiper-pagination-bullet-active {
  background-color: rgba(191, 27, 38, 1);
}

.sliderSwiper .swiper-button-prev {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-basis: max-content;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: auto !important;
  margin: 0px !important;
  padding: 0px !important;
  background-image: none !important;
  top: 0px !important;
  right: -3px !important;
  left: auto !important;
}

.sliderSwiper .swiper-button-next {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: max-content;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: auto !important;
  margin: 0px !important;
  padding: 0px !important;
  background-image: none !important;
  top: 0px !important;
  left: -3px !important;
  right: auto !important;
}

.sliderSwiper .swiper-button-prev::after {
  display: none;
}

.sliderSwiper .swiper-button-next::after {
  display: none;
}

.sliderSwiper .swiper-button-prev,
.sliderSwiper .swiper-button-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .sliderSwiper .izCJif,
  .sliderSwiper .heEmBF {
    display: inline-block !important;
  }
}

.sliderSwiper .heEmBF {
  height: 100%;
  width: auto;
  transform-origin: left center;
  transform: scaleX(1);
  transition: transform 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s;
  display: none;
}

.sliderSwiper .izCJif {
  height: 100%;
  width: auto;
  transform-origin: right center;
  transform: scaleX(1);
  transition: transform 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s;
  display: none;
}

@media only screen and (min-width: 1200px) {
  .sliderSwiper .activeTransform {
    transform: scaleX(2) !important;
  }

  .sliderSwiper .activeTransformSmaller {
    transform: scaleX(2.5) !important;
  }
}

@media only screen and (min-width: 992px) {
  .sliderSwiper .activeTransform {
    transform: scaleX(2.5);
  }

  .sliderSwiper .activeTransformSmaller {
    transform: scaleX(3.5);
  }
}

.sliderSwiper .activeTransform {
  transform: scaleX(2.5);
}

.sliderSwiper .activeTransformSmaller {
  transform: scaleX(3.5);
}

.sliderSwiper .izCJif .sliderSwiper .wave {
  transition: all 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s;
}

.sliderSwiper .activeFill {
  fill: rgb(255, 255, 255);
  transition: all 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s;
}

@media only screen and (min-width: 768px) {
  .sliderSwiper .swiper-button-circle-back-right,
  .sliderSwiper .swiper-button-circle-back-left {
    width: 60px !important;
    height: 60px !important;
  }
}

.sliderSwiper .swiper-button-circle-back-right,
.sliderSwiper .swiper-button-circle-back-left {
  width: 35px;
  height: 35px;
}

@media only screen and (min-width: 768px) {
  .sliderSwiper .swiper-button-circle-right {
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important;
    right: 100% !important;
    transform: translate(100%, -50%) !important;
  }

  .sliderSwiper .active-circle-right {
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important;
    background: transparent !important;
    right: 0px !important;
    transform: translate(-20%, -50%) !important;
  }
}

.sliderSwiper .swiper-button-circle-right {
  background: rgba(255, 255, 255, 0.4);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
}

.sliderSwiper .gHFIbo {
  height: 100%;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .sliderSwiper .swiper-button-circle-left {
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important;
    left: 100% !important;
    transform: translate(-100%, -50%) !important;
  }

  .sliderSwiper .active-circle-left {
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important;
    background: transparent !important;
    left: 0px !important;
    transform: translate(20%, -50%) !important;
  }
}

.sliderSwiper .swiper-button-circle-left {
  background: rgba(255, 255, 255, 0.4);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0%, -50%);
}

.single-product-swiper .swiper-slide-next {
  padding-left: 1px !important;
}

.loader--overlay.loader--loading {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear 0s, visibility 0s linear 0s;
}

.loader {
  display: block;
  position: fixed;
  z-index: 800; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: url("../images/newt.gif") center no-repeat;
  background-size: 110px 110px;
  background-color: rgba(255, 251, 251, 0.299); /* Fallback color */
}

.loader-mobile {
  display: block;
  position: fixed;
  z-index: 800; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: url("../images/newt.gif") center no-repeat;
  background-size: 80px 80px;
  background-color: rgba(255, 251, 251, 0.299); /* Fallback color */
}

.imageScale {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-duration: 2s;
}

.imageScale:hover {
  scale: 1.1 !important;
}

.aside-animation {
  transition: all 0.4s ease;
}

.aside-brand {
  height: 75px;
  padding-left: 25px;
  padding-right: 25px;
}

.aside-menu {
  height: 251px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.aside-menu-list {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0px;
  list-style: none;
  display: block;
}

.aside-menu-list li {
  position: relative;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-grow: 1;
  float: none;
  flex-direction: column;
  width: 100%;
}

.aside-menu-list li a {
  font-size: 22px;
  color: #7c87a6;
  padding: 9px 25px;
  cursor: pointer;
  min-height: 44px;
  display: flex;
  margin: 0px;
  text-decoration: none;
  position: relative;
  align-items: stretch;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
}

.aside-menu-list li a svg {
  display: flex;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30px;
}

.aside-menu-list li a span {
  font-size: 1rem;
  font-weight: 400;
  text-transform: initial;
  display: flex;
  padding: 0;
  align-items: center;
  flex-grow: 1;
  box-sizing: border-box;
}

.menuSellerButton,
.menu-active {
  display: block;
  position: absolute;
  top: 12px;
  height: 2px;
  min-height: 2px;
  width: 100%;
  border-radius: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menuSellerButton::before {
  transition: all 0.4s ease;
  left: 0px;
  right: auto;
  width: 50%;
  background-color: white;
  top: -8px;
  position: absolute;
  display: block;
  height: 2px;
  min-height: 2px;
  content: "";
  border-radius: 2px;
  box-sizing: border-box;
}

.hover-animation:hover span::before,
.hover-animation:hover span::after {
  width: 100%;
}

.menuSellerButton::after {
  transition: all 0.4s ease;
  left: 0px;
  right: auto;
  width: 75%;
  background-color: white;
  bottom: -8px;
  position: absolute;
  display: block;
  height: 2px;
  min-height: 2px;
  content: "";
  border-radius: 2px;
  box-sizing: border-box;
}

.menu-active::before {
  transition: all 0.4s ease;
  left: auto;
  right: 0px;
  width: 50%;
  background-color: white;
  top: -8px;
  position: absolute;
  display: block;
  height: 2px;
  min-height: 2px;
  content: "";
  border-radius: 2px;
  box-sizing: border-box;
}

.menu-active::after {
  transition: all 0.4s ease;
  left: auto;
  right: 0px;
  width: 75%;
  background-color: white;
  bottom: -8px;
  position: absolute;
  display: block;
  height: 2px;
  min-height: 2px;
  content: "";
  border-radius: 2px;
  box-sizing: border-box;
}

.active-link {
  background-color: #1f2433;
}

.active-link span {
  color: white;
}

.active-link svg {
  color: #5d78ff;
}

.sellerInfo {
  display: flex;
  height: 160px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
  background-color: #ffffff;
  margin-bottom: 20px;
  border-radius: 4px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 1rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search_button {
  cursor: pointer;
  align-items: center;
  color: #fff;
  background-color: #5d78ff !important;
  border-color: #5d78ff;
  outline: none !important;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.reset_button {
  cursor: pointer;
  align-items: center;
  background-color: transparent !important;
  border: 1px solid #e2e5ec !important;
  color: #595d6e;
  outline: none !important;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.order-status {
  height: 687px;
  flex-basis: 33.3333%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 33.33%;
}

.order-table {
  height: 687px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 65.66667%;
  max-width: 66.67%;
}

.table-header {
  border-bottom: 1px solid #ebedf2;
  min-height: 60px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dashboard-table {
  width: 100% !important;
  border-collapse: initial !important;
  border-spacing: 0 !important;
  margin: 1rem 0 !important;
  border: 1px solid #ebedf2;
  color: #212529;
  background-color: transparent;
}

.dashboard-table thead th,
.dashboard-table thead td {
  font-weight: 500;
  border-bottom-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid #ebedf2;
}

.dashboard-table td,
.dashboard-table th {
  color: #595d6e;
  padding: 0.65rem;
  vertical-align: top;
  border: 1px solid #ebedf2;
}

.aside-small-menu {
  box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 75%);
}

.aside-small-menu ul li a svg {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.aside-overlay {
  background: rgba(0, 0, 0, 0.1);
}

.seller-pagination ul {
  padding-left: 10px;
}

.seller-pagination ul li {
  height: 30px;
  width: auto;
  min-width: 30px;
  margin-left: 10px;
}

.seller-pagination ul li a {
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: auto;
  vertical-align: middle;
  padding: 4px;
  text-align: center;
  position: relative;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  color: #595d6e;
  border: 0;
  outline: none !important;
  background: #ebe9f2;
}

.seller-pagination .active-pagination a {
  background-color: #5d78ff !important;
  color: #ffffff !important;
}

.seller-pagination ul li a:hover {
  background-color: #5d78ff !important;
  color: #ffffff !important;
}

.seller-report-pagination {
  display: flex;
}

.seller-dot::after {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  content: " ";
  background: rgba(149, 156, 182, 0.5);
}

.seller-accounting {
  flex-basis: 20%;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 20%;
}
.swiper-slide {
  width: auto !important;
  height: auto !important;
}

.swiper-button-next  {
  z-index: 1;
  position: absolute;
  right: 0px !important;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  color:darkgray !important;
  width: 50px !important;
  height: 100% !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 5px !important;
}



.swiper-button-prev{
  z-index: 1;
  position: absolute;
  left: 0px !important;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  color:darkgray !important;
  width: 50px !important;
  height: 100% !important;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 5px;
}

.swiper-button-prev{
  z-index: 1;
  position: absolute;
  left: 0px !important;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  color:darkgray !important;
  width: 50px !important;
  height: 100% !important;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 5px;
}
.swiper-button-next:after{
  font-size: 30px !important;
}
.swiper-button-prev::after{
  font-size: 30px !important;
}
.my-custom-pagination-div .swiper-pagination-bullet {
  margin: 5px 2.5px;
  width: 8px;
  height: 8px;
  border: 1px solid rgb(86, 89, 97);
  border-radius: 50%;
  background-color: transparent;
}

.my-custom-pagination-div .swiper-pagination-bullet-active {
  background-color: rgb(56, 102, 223);
  border: 1px solid rgb(56, 102, 223);
}

.menu-hover {
  transition: all 0.1s ease;
}

.menu-hover:hover {
  background-color: white;
  box-shadow: 0 0 40px 1px rgba(121, 121, 121, 0.264);
  clip-path: inset(0px -2px 0px -2px);
  /* box-shadow: 3px 0px 30px 3px rgba(120, 120, 120, 0.19); */
}

/* .overlay-header {
  border-top: 1px solid rgb(226, 229, 241);
} */

.product-height {
  height: 680px;
}

.underline_effect {
  position: relative;
  color: rgb(56, 102, 223);
  text-decoration: none;
}
.underline_effect:hover {
  color: #000;
}
.underline_effect::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(56, 102, 223);
  transform: scaleX(1);
  transition: transform 0.3s ease;
}
.underline_effect:hover::before {
  transform: scaleX(0);
}

.sizeClear {
  position: relative;
  color: rgb(56, 102, 223);
  text-decoration: none;
  font-size: 14px;
}
.sizeClear:hover {
  color: #000;
}
.sizeClear::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgb(56, 102, 223);
  transform: scaleX(1);
  transition: transform 0.2s ease;
}
.sizeClear:hover::before {
  transform: scaleX(0);
}

.catalog-top-filter {
  position: absolute;
  top: calc(100% - 0px);
  z-index: 5;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 10px;
  border-radius: 8px;
  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  max-height: 0px;
  overflow: hidden;
}

.catalog-top-filter-open {
  max-height: 600px;
  overflow: auto;
}

.catalog-top-filter-open::-webkit-scrollbar {
  width: 3px;
}

.catalog-top-filter-container {
  max-width: 650px;
  min-width: 400px;
  max-height: 300px;
  width: auto;
  height: auto;
}

.mobile-catalog-filter {
  position: fixed;
  inset: 0px;
  z-index: 80;
  touch-action: none;
  pointer-events: none;
}

.mobile-catalog-filter-open {
  touch-action: auto !important;
  pointer-events: auto !important;
}

.mobile-catalog-blur {
  position: absolute;
  inset: 0px;
  opacity: 0;
  transition: all 0.1s ease 0s;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
}

.mobile-catalog-container {
  position: absolute;
  background-color: rgb(255, 255, 255);
  left: 0px;
  right: 0px;
  height: 80%;
  bottom: -120%;
  border-radius: 16px 16px 0px 0px;
  transition: bottom 0.4s ease-in-out 0s;
}

.mobile-catalog-container-before::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  height: 4px;
  border-radius: 4px;
  background-color: rgb(218, 220, 227);
}

.catalog-top-filter-selected {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(56, 102, 223);
  color: rgb(56, 102, 223);
}

.catalog-top-filter-not-selected {
  background-color: rgb(240, 242, 247);
  border: 1px solid rgb(240, 242, 247);
}

.button-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.button-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.catalog-filter-nav {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
  font-size: 18px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 5px;
}

.catalog-mobile-scroll {
  overscroll-behavior: contain;
}

.catalog-mobile-scroll::-webkit-scrollbar {
  width: 3px;
}

.myFilterSwiper .swiper-button-disabled {
  display: none;
}

.myFilterSwiper .swiper-slide {
  width: fit-content !important;
}

.myFilterSwiper .swiper-button-next {
  z-index: 1;
  position: absolute;
  right: 0px;
  cursor: pointer;
  padding: 12px;
  font-size: 18px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 5px;
}

.myFilterSwiper .swiper-button-next::after {
  color: rgb(126, 133, 155);
  font-size: 18px;
}

.myFilterSwiper .swiper-button-prev {
  z-index: 1;
  position: absolute;
  cursor: pointer;
  padding: 12px;
  left: 0px;
  font-size: 18px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 5px;
}

.myFilterSwiper .swiper-button-prev::after {
  color: rgb(126, 133, 155);
  font-size: 18px;
}

.blurProduct {
  filter: blur(0.3rem);
}

.sideImgBlur {
  filter: blur(1px);
}
.shareList {
  animation-name: showup;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.my-custom-pagination-div {
  display: flex;
  justify-content: center;
}

.add_images_upload {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.aside-profile {
  width: 100% !important;
}

@media (min-width: 768px) {
  .aside-profile {
    width: 280px !important;
  }
}

/* a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important} */

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.verifyPhone {
  position: absolute;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  inset: 0px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  height: 350px;
}

@media only screen and (min-width: 768px) {
  .verifyPhone {
    left: 50% !important;
    top: 50% !important;
    right: unset !important;
    bottom: unset !important;
    transform: translate(-50%, -50%) !important;
  }
}

.verifyPhone-button {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px;
}

@media only screen and (min-width: 768px) {
  .verifyPhone-button {
    display: block !important;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 8px !important;
    margin-top: 24px !important;
    position: relative;
    left: unset !important;
    right: unset !important;
    bottom: 0px !important;
    z-index: 0;
    background-color: unset !important;
    box-shadow: none !important;
  }
}

.seller-edit-form-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.seller-edit-form-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ck-editor__editable {
  overflow-y: auto;
  max-height: 240px;
  min-height: 240px;
}

.ck-editor__editable ul,
.ck-editor__editable ol {
  padding: 0px 0px 0px 40px;
  margin: 16px 0px;
}

.ck-editor__editable ul {
  list-style-type: disc;
}

.ck-editor__editable ol {
  list-style: decimal;
}

.ck-editor__editable h1 {
  font-size: 32px;
}

.ck-editor__editable h2 {
  font-size: 26px;
}

.ck-editor__editable h3 {
  font-size: 22px;
}

.ck-editor__editable {
  color: #636c9b;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.ck-toolbar {
  background-color: #fafafa;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .placeholder_animation {
    background-image: radial-gradient(
      rgba(254, 254, 254, 0.925),
      rgba(215, 211, 223, 0.474)
    );
    /* background-image: radial-gradient(rgba(254, 254, 254, 0.925), rgba(210, 210, 210, 0.323)); */
    /* background-image: radial-gradient(
      
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.7) 60%,
      rgba(255, 255, 255, 0) 100%,

    ); */
    background-size: 200% 100%;
    animation: anmBg 0.8s linear infinite;
  }
  @keyframes anmBg {
    to {
      background-position: -170% 0;
    }
  }
  @media (max-width: 650px) {
    .placeholder_animation {
      background-image: radial-gradient(
        rgba(254, 254, 254, 0.925),
        rgba(215, 211, 223, 0.474)
      );
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 210px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 5px 4px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 45%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Tooltip container */
.tooltipp {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltipp .tooltiptextt {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.575);
  color: #fff;
  text-align: center;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 25px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipp:hover .tooltiptextt {
  visibility: visible;
}
._1hzhf {
  font-size: 12px;
}

._2Jtxm._35Ago ._2kL0S {
  background-color: #e7eaf3 !important;
  color: rgb(191, 27, 38) !important;
}

._3uApM {
  display: none !important;
}

._53Ji7 {
  padding: 0 !important;
}

._2_g61 {
  display: none !important;
  padding: 0 !important;
}

._2Jtxm._2ZUAI ._2JvrO {
  background-color: rgb(191, 27, 38) !important;
}

._2Jtxm::before {
  background: linear-gradient(
    to left,
    #e7eaf3 50%,
    rgb(191, 27, 38) 50%
  ) !important;
  background-size: 200% 100% !important;
  background-position: right bottom !important;
}

._2Jtxm._2ZUAI::before,
._2Jtxm._35Ago::before,
._2Jtxm._1CcaK::before {
  background-position: left bottom !important;
}

._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
  color: rgb(191, 27, 38) !important;
}

._1Lo2h > li:nth-child(5) {
  display: none !important;
}

@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 0;
  }
  html,
  body {
    width: 210mm;
    /* height: 297mm; */
    height: 282mm;
    font-size: 11px;
    background: #fff;
    overflow: visible;
  }
  body {
    padding: 0.5cm;
  }
}

/* body{margin-top:20px;} */

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  /* text-align: center */
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 20px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  /* line-height: 81px; */
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #3866de;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 21px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider_swicth {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_swicth:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider_swicth {
  background-color: #2196f3;
}

input:focus + .slider_swicth {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider_swicth:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider_swicth.round {
  border-radius: 34px;
}

.slider_swicth.round:before {
  border-radius: 50%;
}

.steps .step.completed .step-icon {
  border-color: #3866de;
  background-color: #3866de;
  color: #fff;
}

@media (max-width: 300px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

@media print {
  body {
    width: 210mm;
    height: 297mm;
    margin: 0;
  }
}

.hp {
  line-height: 31.36px;
}

.hpm {
  line-height: 25px;
}

@media print {
  pre,
  blockquote {
    page-break-inside: avoid;
  }
  #grid-div {
    margin-right: 20px;
  }
}

.img-zoom-result {
  /* border: 1px solid #d4d4d4; */
  /*set the size of the result div:*/
  width: 398px;
  height: 542.77px;
}

.img-zoom-result {
  animation-name: showup;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes showup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.img-zoom-lens {
  position: absolute;
  border: 1px solid transparent;
  /*set the size of the lens:*/
  width: 140px;
  height: 140px;
}

.product-big-img .slick-slide > div > div {
  display: flex !important;
  justify-content: center !important;
}

@media (max-width: 840px) {
  #popup_modal::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 130%;
    height: 100%;
    overflow-y: hidden;
    margin-left: -30px;
    margin-top: -15px;

    background-size: cover;
    filter: blur(30px);
    z-index: -1;
  }
}
@media (max-width: 840px) {
  .thumbss-center-slider .slick-track {
    place-items: center;
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 100% !important;
  }
}

/* Styles specific to Safari */
@supports (-webkit-touch-callout: none) {
  .browser-height {
    width: 86%;
  }
}

/* Styles specific to other browsers */
@supports not (-webkit-touch-callout: none) {
  .browser-height {
    width: 100%;
  }
}

.slick-slider {
  touch-action: inherit !important;
}

.single-product-img-slider .slick-slide {
  pointer-events: none;
}
.single-product-img-slider .slick-active {
  pointer-events: auto;
}

.mobile-modal-single-product-img-slider .slick-slide {
  pointer-events: none;
}
.mobile-modal-single-product-img-slider .slick-active {
  pointer-events: auto;
}

.modal-single-product-img-slider .slick-slide {
  pointer-events: none;
}
.modal-single-product-img-slider .slick-active {
  pointer-events: auto;
}
.single-product-img-slider .slick-slider {
  touch-action: pan-y !important;
}

@media (max-width: 768px) {
  .single-product-img-slider .slick-track {
    display: flex !important;
  }
  .single-product-img-slider .slick-slide {
    height: inherit !important;
  }
  .single-product-img-slider .slick-slide > div {
    height: 100% !important;
  }
}

@media (max-width: 840px) {
  .popup-open {
    overflow-y: hidden !important;
    position: fixed;
    width: 100%;
    height: 100vh;
  }
}

.modal_zoom_div {
  height: 100%; /* Fallback */
  height: -moz-available;
  height: -webkit-fill-available;
  /* height: fill-available; */
  height: stretch; /* Latest specification */
}

/* styles/styles.module.css */

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
}

.ticket {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stub,
.check {
  box-sizing: border-box;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.2);
  border-top: solid 2px #bf1b26;
}
.stub {
  background: white;
  height: 100%;
  width: 30%;
  padding: 20px 7px 20px 7px;
  position: relative;
  border-radius: 10px;
}
.stub::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 10px solid #f7f7fa;
  background: white;
  width: 0;
}
.stub::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 10px solid #f7f7fa;
  /* border-left: 20px solid #EF5658; */
  background: white;
  width: 0;
}
.top {
  display: flex;
  align-items: center;
  height: 40px;
  text-transform: uppercase;
}
.check {
  background: white;
  width: 70%;
  position: relative;
  border-radius: 10px;
}
.check::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 10px solid #f7f7fa;
  background: white;
}
.check::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #f7f7fa;
  background: white;
}
.divider-coupon {
  width: 1px;
  border: 1px dashed #f4d0b1;
  height: 85%;
}

.shareList {
  animation-name: showup;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slider-container {
  position: relative;
  overflow: hidden;
  /* background: linear-gradient(to bottom, #bf1b26,#c43842, white); */
  width: 100%;
  white-space: nowrap; /* Change 10s to the duration you desire */
}

@media screen and (min-width: 650px) {
  .images-slide {
    animation: 20s slideRightbigScreen infinite linear;
  }
}
@media screen and (max-width: 650px) {
  .images-slide {
    animation: 20s slideRight infinite linear;
  }
}
@keyframes slideRight {
  from {
    background-position: 0%;
  }
  to {
    background-position: 164%;
  }
}

@keyframes slideRightbigScreen {
  from {
    background-position: 0%;
  }
  to {
    background-position: 206%;
  }
}

.images-slide {
  width: 100%;
  background-repeat: repeat-x;
  height: 100%;
  background-image: url("../images/registerImg.png");
  background-position: center;
  background-size: cover;
  /* animation: 10s slideRight infinite linear; */
}
/* Hide scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.np::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.np {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.np::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.np {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@layer utilities {
  .test {
    padding-right: 1%rem; /* 14px */
  }

  .scrollbar::-webkit-scrollbar {
    width: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #b6b6b6;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #b6b6b6;
    opacity: 1;
    display: block;
  }
}
.mySwiper.sliderCategories .swiper-button-next {
  position: absolute !important;
  top: 46% !important;
  transform: translateY(25%);
  height: 30px !important;
  width: 30px !important;
  border-radius: 100% !important;
  /* box-shadow: rgb(0 0 0 / 50%) 0px 0px 3px -1px !important; */
  border: 1px solid rgb(204, 204, 204) !important;
  background: rgb(255, 255, 255) !important;
  z-index: 1;
  right: -1%;
  /* margin-right: -20px; */
}

.mySwiper.sliderCategories .swiper-button-prev {
  position: absolute !important;
  top: 46% !important;
  transform: translateY(25%);
  height: 30px !important;
  width: 30px !important;
  border-radius: 100% !important;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 3px -1px !important;
  border: 1px solid rgb(204, 204, 204) !important;
  background: rgb(255, 255, 255) !important;
  z-index: 1;
  left: -1%;
  /* margin-left: -2px !important; */
}
.clamp-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 650px) {
  .headersticky.hide {
    top: -90px;
  }
}

.headersticky {
  position: sticky;
  z-index: 30;
  top: 0;
  transition: all 0.5s ease;
}
.bottombar {
  position: sticky;
  z-index: 25;
  bottom: 0;
  transition: all 0.5s ease;
}
.bottombar.hide {
  bottom: -60px;
}
@media (min-width: 650px) {
  .headersticky.hide {
    top: -63px;
  }
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.show-scrollbar:hover::-webkit-scrollbar {
  width: 4px;
  background: #ccc;
}

.lds-ellipsis-white {
  color: #ffffff; /* Set color to white */
}
.lds-ellipsis-white,
.lds-ellipsis-white div {
  box-sizing: border-box;
}
.lds-ellipsis-white {
  display: inline-block;
  position: relative;
  width: 40px; /* Reduced width */
  height: 40px; /* Reduced height */
}
.lds-ellipsis-white div {
  position: absolute;
  top: 18px; /* Adjusted position for smaller size */
  width: 6.66667px; /* Reduced width */
  height: 6.66667px; /* Reduced height */
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis-white div:nth-child(1) {
  left: 4px; /* Adjusted left position */
  animation: lds-ellipsis-white1 0.6s infinite;
}
.lds-ellipsis-white div:nth-child(2) {
  left: 4px; /* Adjusted left position */
  animation: lds-ellipsis-white2 0.6s infinite;
}
.lds-ellipsis-white div:nth-child(3) {
  left: 16px; /* Adjusted left position */
  animation: lds-ellipsis-white2 0.6s infinite;
}
.lds-ellipsis-white div:nth-child(4) {
  left: 28px; /* Adjusted left position */
  animation: lds-ellipsis-white3 0.6s infinite;
}

@keyframes lds-ellipsis-white1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis-white3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis-white2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0); /* Adjusted translation for smaller size */
  }
}

.dropdown-icon {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 5px;
  cursor: pointer;
}

.dropdown-icon span {
  display: block;
  position: absolute;
  width: 8px;
  height: 2px;
  background-color: #fffefe;
  transition: transform 0.4s ease;
  border-radius: 30px;
}

.dropdown-icon span:nth-child(1) {
  top: 6px;
  left: 2px;
  transform: rotate(-45deg);
}

.dropdown-icon span:nth-child(2) {
  top: 6px;
  left: -2px;
  transform: rotate(45deg);
}

.dropdown-icon.open span:nth-child(1) {
  transform: rotate(45deg);
}

.dropdown-icon.open span:nth-child(2) {
  transform: rotate(-45deg);
}


 .selector_div .slick-arrow .arrow {
    width: 50%;
    height: 10px;
  } 

  .selector_div .slick-arrow .slick-prev {
    width: 100%;
    height: 20px;
    top: 0;
    z-index: 1;
    -webkit-box-pack: center;
    position: absolute !important;
    cursor: pointer;
    left: unset !important;
  }
  .selector_div .slick-prev{
    left: unset !important;
    transform: translate(0, -28%) !important;
  }
  .selector_div .slick-next{
    transform: rotate(180deg) !important;
    top: unset !important;
  }
  .selector_div .slick-arrow .slick-next{
    width: 100%;
    height: 20px;
    bottom: 0;
    z-index: 1;
    -webkit-box-pack: center;
    position: absolute !important;
    cursor: pointer;
    translate: unset !important;
  
  } 
  .selector_div .slick-arrow.slick-button-disabled {
    display: none;
  }
  .selector_div .slick-slider{
    position: relative;
 }
 .selector_div .slick-arrow.slick-hidden {
    display: none;
  }

  .selector_div .slick-list{
    height: 439px !important;
    
 }
 @media (max-width: 768px){
  .selector_div  .slick-list{
    height: unset !important;   
    
 }
 }
    .selector_div {
      min-height: 550px;
      min-width: 50px; 
   }

   @media (max-width: 768px){
    .selector_div {
      min-height: unset !important;
      min-width: unset !important;        
   }
   }
  

   .selector_div .slick-slider .slick-track{
    height: unset !important;
  }
  .selector_div .slick-disabled {
    display: none !important;
  }
  .go-up {
    top: 0px;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0) 100%);
  }
   .go-down {
    transform: rotate(180deg);
    bottom: 0px;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0) 100%);
  }

  .selectors img[src], .selectors img[srcset]{
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .selectors img[srcset]{
    height: 103px !important;
    width: 76px !important;
  }

  .mz-thumb.mz-thumb-selected img {
    border-width: 2px !important;
  }

  .selector_div  .slick-slide {
     margin-bottom: 10px !important; 
  }